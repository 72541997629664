#gameScreen {
  border: 1px solid black;
}

img {
  display: none;
}

.buttonPad {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.controlBtn {
  width: 100%;
  height: 20vh;
  font-size: 40;
  margin: 5;
}

.gameCanvas {
  width: 100%;
  height: 79%;
  background-color: black;
}